import React, { ReactNode } from 'react';
import Slider, { ResponsiveObject } from 'react-slick';

interface CarouselProps {
    slidesToShow: number;
    beforeChange?: (currentSlide: number, nextSlide: number) => void;
    children?: ReactNode;
    dark?: boolean;
    responsive?: ResponsiveObject[];
}

const Carousel = ({ beforeChange, children, dark, responsive, slidesToShow }: CarouselProps) => {
    return (
        <div className={`carousel ${dark ? 'dark' : ''}`}>
            <Slider slidesToShow={slidesToShow} slidesToScroll={slidesToShow} speed={500} infinite={false} responsive={responsive} beforeChange={beforeChange} arrows dots>
                {children}
            </Slider>
        </div>
    );
};

export default Carousel;
