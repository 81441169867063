import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { hooks } from './ts/hooks';
import { HookProvider } from './ts/providers';
import configureStore from './ts/store/configureStore';
import routes from './routes';
import 'rc-slider/assets/index.css';
import './css/styles.css';

const store = configureStore();

render(
    <Provider store={store}>
        <HookProvider hooks={hooks}>
            <BrowserRouter>
                {routes}
            </BrowserRouter>
        </HookProvider>
    </Provider>,
    document.getElementById('root')
);
