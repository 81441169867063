import { Translations } from '../models';

const getTranslations = <T extends Translations>(translations: T): T => translations;

export const TRANSLATIONS = getTranslations({
    'selector': {
        'title': [
            'Primary Features',
            'Primäre Merkmale'
        ],
        'code': [
            'Code',
            'Code'
        ],
        'voltage': [
            'Voltage / Poles',
            'Spannung / Pole'
        ],
        'rangeTitle': [
            'Current',
            'Aktuell'
        ],
        'application': [
            'Application',
            'Applikation'
        ],
        'withWithoutProtection': [
            'With/without protection',
            'Mit/ohne schutz'
        ],
        'protection': [
            'Protection',
            'Schutz'
        ],
        'overloadProtection': [
            'Overload Protection',
            'Überlastschutz'
        ],
        'monitoring': [
            'Monitoring',
            'Überwachung'
        ],
        'communication': [
            'Communication',
            'Kommunikation'
        ],
        'voltageRangeMaxIec': [
            'Voltage Range Max IEC',
            'Spannungsbereich Max IEC'
        ],
        'voltageRangeMaxUl': [
            'Voltage Range Max UL',
            'Spannungsbereich Max UL'
        ],
        'currentMinNominal': [
            'Current Min Nominal',
            'Strom Min Nominal'
        ],
        'configure': [
            'Configure',
            'Konfigurieren'
        ]
    },
    'footer': {
        'contactUs': [
            'Contact us',
            'Kontakt'
        ],
        'contactUsLink': [
            'http://new.siemens.com/global/en/general/contact.html',
            'https://new.siemens.com/global/de/general/kontakt.html'
        ],
        'cookieNotice': [
            'Cookie Notice',
            'Cookie-Hinweise'
        ],
        'cookieNoticeLink': [
            'https://new.siemens.com/global/en/general/cookie-notice.html',
            'https://new.siemens.com/global/de/general/cookie-richtlinien.html'
        ],
        'corporateInformation': [
            'Corporate Information',
            'Impressum'
        ],
        'corporateInformationLink': [
            'https://new.siemens.com/global/en/general/legal.html',
            'https://new.siemens.com/global/de/general/legal.html'
        ],
        'digitalId': [
            'Digital ID',
            'Digitales Zertifikat'
        ],
        'digitalIdLink': [
            'https://new.siemens.com/global/en/general/digital-id.html',
            'https://new.siemens.com/global/de/general/digitales-zertifikat.html'
        ],
        'siemensWebsite': [
            'siemens.com Global Website',
            'siemens.com Germany'
        ],
        'siemensWebsiteLink': [
            'https://new.siemens.com/global/en.html',
            'https://new.siemens.com/global/de.html'
        ],
        'privacyNotice': [
            'Privacy Notice',
            'Datenschutz'
        ],
        'privacyNoticeLink': [
            'https://new.siemens.com/global/en/general/privacy-notice.html',
            'https://new.siemens.com/global/de/general/datenschutz.html'
        ],
        'siemens': [
            '© Siemens, 1996 - {date}',
            '© Siemens, 1996 - {date}'
        ],
        'termsOfUse': [
            'Terms of use',
            'Nutzungsbedingungen'
        ],
        'termsOfUseLink': [
            'https://new.siemens.com/global/en/general/terms-of-use.html',
            'https://new.siemens.com/global/de/general/nutzungsbedingungen.html'
        ]
    },
    'header': {
        'language': [
            'Language',
            'Sprache'
        ],
        'languages': {
            'en': [
                'English',
                'Englisch'
            ],
            'de': [
                'Deutsch',
                'Deutsch'
            ]
        },
        'skipToMainContent': [
            'Skip to main content',
            'Zum Hauptinhalt springen'
        ],
        'support': [
            'Support',
            'Unterstützung'
        ],
        'reportProblem': [
            'Report a problem',
            'Ein Problem melden'
        ]
    },
    'error': {
        'wrongMinMax': [
            'The current minimum can not be that high.',
            'Das aktuelle Minimum kann nicht so hoch sein.'
        ],
        'wrongMaxMin': [
            'The current maximum can not be that low.',
            'So niedrig kann das aktuelle Maximum gar nicht sein.'
        ],
        'noResults': [
            'No results',
            'Keine Ergebnisse'
        ],
        'missingFilter': [
            'Select all primary filters.',
            'Wählen Sie alle primären Filter aus.'
        ]
    }
});
