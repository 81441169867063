import React, { FocusEvent, KeyboardEvent, useRef, useState } from 'react';
import { useOpenAnimations } from '../../hooks/common';
import Icon, { IconType } from '../common/Icon';

export interface HeaderDropdownAction {
    name: string;
    onClick: () => void;
    disabled?: boolean;
    iconType?: IconType;
    keepOpen?: boolean;
}

export interface HeaderActionProps {
    dropdownActions: HeaderDropdownAction[];
    iconType: IconType;
    title: string;
}

const HeaderAction = ({ dropdownActions, iconType, title }: HeaderActionProps) => {
    const [open, setOpen] = useState(false);
    const [animationClass, handleAnimationEnd] = useOpenAnimations(open);
    const buttonRef = useRef<HTMLDivElement>(null);

    const handleBlur = (event: FocusEvent<HTMLDivElement>) => {
        if (!event.relatedTarget || !buttonRef.current || !buttonRef.current.contains(event.relatedTarget as HTMLElement)) {
            setOpen(false);
        }
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            setOpen(x => !x);
        }
    };

    const selectAction = (action: HeaderDropdownAction) => {
        if (!action.disabled) {
            !action.keepOpen && setOpen(false);
            action.onClick();
        }
    };

    const handleActionKeyDown = (event: KeyboardEvent<HTMLDivElement>, action: HeaderDropdownAction) => {
        event.stopPropagation();

        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            selectAction(action);
        }
    };

    return (
        <div className='header-action'>
            <div ref={buttonRef} className='header-action-button' tabIndex={0} onBlur={handleBlur} onClick={() => setOpen(x => !x)} onKeyDown={handleKeyDown}>
                <Icon type={iconType} />
                <div className={`header-action-dropdown ${animationClass}`} onClick={e => e.stopPropagation()} onAnimationEnd={handleAnimationEnd}>
                    <div className='header-action-dropdown-title'>
                        {title}
                    </div>
                    {dropdownActions.map(x =>
                        <div
                            key={x.name}
                            className={`header-action-dropdown-action ${x.disabled ? 'disabled' : ''}`}
                            tabIndex={0}
                            onClick={e => {
                                e.stopPropagation();
                                selectAction(x);
                            }}
                            onKeyDown={e => handleActionKeyDown(e, x)}
                        >
                            {x.iconType && <Icon type={x.iconType} />}
                            <div>{x.name}</div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default HeaderAction;
