import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators, ApplicationState } from '../../store';
import { TagProps } from '../common/Tag';
import { SectionColor } from '../common/Section';
import TagBar from '../common/TagBar';
import { useHook } from '../../hooks';
import { resetApplicationAndRange, resetCodeAndRange, resetVoltageAndRange, setCurrentMinMaxFilter } from '../../services';

interface SelectorTagBarProps {
    backgroundColor: SectionColor;
}

const SelectorTagBar = ({ backgroundColor }: SelectorTagBarProps) => {
    const dispatch = useDispatch();
    const filter = useSelector((state: ApplicationState) => state.selector.filter);
    const filterValues = useSelector((state: ApplicationState) => state.selector.filterValues);
    const { onFilterRequest } = useHook(x => x.filterRequest)();

    const getTagProps = (text: string, onClick: () => void): TagProps => ({ text, onClick, close: true });

    const handleCodeReset = async () => {
        const newFilter = resetCodeAndRange(filter);

        await dispatch(actionCreators.setFilter(newFilter));

        await onFilterRequest(newFilter);
    };

    const handleVoltageReset = async () => {
        const newFilter = resetVoltageAndRange(filter);

        await dispatch(actionCreators.setFilter(newFilter));

        await onFilterRequest(newFilter);
    };

    const handleApplicationReset = async () => {
        const newFilter = resetApplicationAndRange(filter);

        await dispatch(actionCreators.setFilter(newFilter));

        await onFilterRequest(newFilter);
    };

    const handlePowerReset = async () => {
        const newFilter = setCurrentMinMaxFilter(filter, filterValues.currentMinValue, filterValues.currentMaxValue);

        await dispatch(actionCreators.setFilter(newFilter));

        await onFilterRequest(newFilter);
    };

    const tags: TagProps[] = [
        ...filter.code
            ? [getTagProps(filter.code, handleCodeReset)]
            : [],
        ...filter.voltageWithPole
            ? [getTagProps(filter.voltageWithPole, handleVoltageReset)]
            : [],
        ...filter.applicationShortName
            ? [getTagProps(filter.applicationShortName, handleApplicationReset)]
            : [],
        ...filter.currentMinValue && filter.currentMaxValue
            ? [getTagProps(`${filter.currentMinValue} - ${filter.currentMaxValue} A`, handlePowerReset)]
            : []
    ];

    return (
        <div className='selector-tag-bar'>
            <TagBar tags={tags} backgroundColor={backgroundColor} />
        </div>
    );
};

export default SelectorTagBar;
