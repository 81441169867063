import React, { ReactText } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { Language } from '../../enums';
import Icon, { IconType } from '../common/Icon';
import Button, { ButtonType } from '../common/Button';
import vaImage from '../../../assets/images/products/3VA.jpg';
import vaUlImage from '../../../assets/images/products/3VA_UL.jpg';
import waImage from '../../../assets/images/products/3WA.jpg';
import va27Image from '../../../assets/images/products/3VA27.jpg';
import wlImage from '../../../assets/images/products/3WL.jpg';
import wlUpgradeImage from '../../../assets/images/products/3WL_upgrade.jpg';
import wl10Image from '../../../assets/images/products/3WL10.jpg';
import { Product } from '../../models';
import { useTranslate } from '../../hooks/common';
import { TRANSLATIONS } from '../../constants';

interface CardProps {
    product: Product
}

const Card = ({ product }: CardProps) => {
    const translate = useTranslate();
    const language = useSelector((state: ApplicationState) => state.translation.language);

    const getThumbnailImage = () => {
        switch (product.kmat) {
            case '3VA':
                return vaImage;
            case '3VA UL':
                return vaUlImage;
            case '3VA27':
                return va27Image;
            case '3WA':
                return waImage;
            case '3WL UL':
                return wlImage;
            case '3WL10':
                return wl10Image;
            case '3WL Upgrade':
                return wlUpgradeImage;
        }
    };

    const getValueByLanguageKey = (value: Record<string, string>): string => value[Language[language].toLocaleUpperCase()];

    const renderProperty = (label: ReactText, value: string, tripleHeight = false, isLabelNeeded = true) => (
        <div className='property-container'>
            <div className='property'>
                {isLabelNeeded && <div className='property-label'>
                    {label}
                </div>}
                <div className={`property-value ${tripleHeight ? 'triple-height' : ''}`}>
                    {value}
                </div>
            </div>
        </div>
    );

    return (
        <div className='card'>
            <div className='thumbnail'>
                <img src={getThumbnailImage()} />
            </div>
            <div className='header'>
                <div className='name-and-actions'>
                    <div
                        className='mlfb'
                        onClick={() => window.open(`https://${product.marketingData.marketingUrl}`, '_blank')}
                    >
                        {product.kmat}
                    </div>
                </div>
            </div>
            <div className='details' >
                {renderProperty(
                    translate(TRANSLATIONS.selector.application),
                    getValueByLanguageKey(product.applicationLong),
                    true
                )}
                {renderProperty(
                    translate(TRANSLATIONS.selector.overloadProtection),
                    getValueByLanguageKey(product.overloadProtection)
                )}
                {renderProperty(
                    translate(TRANSLATIONS.selector.monitoring),
                    product.monitoring,
                    false
                )}
                {renderProperty(
                    translate(TRANSLATIONS.selector.communication),
                    product.communication.length === 0 ? '-/-' : product.communication
                )}
                {renderProperty(
                    translate(TRANSLATIONS.selector.voltageRangeMaxIec),
                    product.voltageRangeMaxIec
                )}
                {renderProperty(
                    translate(TRANSLATIONS.selector.currentMinNominal),
                    product.currentMinNominal.toString()
                )}
                {renderProperty(
                    translate(TRANSLATIONS.selector.voltageRangeMaxUl),
                    product.voltageRangeMaxUl.length === 0 ? '-/-' : product.voltageRangeMaxUl
                )}
                {renderProperty(
                    translate(TRANSLATIONS.selector.protection),
                    product.protection,
                    true,
                    false
                )}
                {renderProperty(
                    translate(TRANSLATIONS.selector.withWithoutProtection),
                    getValueByLanguageKey(product.withOrWithoutProtection),
                    false,
                    false

                )}
            </div>
            <div className='actions'>
                <Button
                    type={ButtonType.Primary}
                    onClick={() => window.open(`https://${getValueByLanguageKey(product.marketingData.configuratorUrl)}`, '_blank')}
                >
                    <Icon type={IconType.Configuration} />
                    {translate(TRANSLATIONS.selector.configure)}
                </Button>
            </div>
        </div>
    );
};

export default Card;
