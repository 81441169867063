import React, { useEffect, FocusEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Language } from '../../enums';
import { useTranslate } from '../../hooks/common';
import { actionCreators, ApplicationState } from '../../store';
import { TRANSLATIONS, carouselResponsive } from '../../constants';
import { SnackbarType } from '../common/Snackbar';
import Flex, { FlexDirection, FlexJustification } from '../common/Flex';
import ContentContainer from '../common/ContentContainer';
import Jumbotron from '../common/Jumbotron';
import KeyVisual from '../common/KeyVisual';
import Loader from '../common/Loader';
import Section, { SectionColor } from '../common/Section';
import Banner from '../../../assets/images/banner/banner.jpg';
import Slider, { createSliderWithTooltip } from 'rc-slider';
import Select from '../common/Select';
import Carousel from '../common/Carousel';
import Card from './Card';
import TextInput from '../common/TextInput';
import SelectorTagBar from './SelectorTagBar';
import { useHook } from '../../hooks';
import { getValueFromDictionaryByLanguage, getValuesByLanguageKey, setApplicationFilter, setCodeFilter, setCurrentMaxFilter, setCurrentMinFilter, setCurrentMinMaxFilter, setVoltageFilter } from '../../services';
import { Filter } from '../../models';

const Selector = () => {
    const dispatch = useDispatch();
    const translate = useTranslate();
    const Range = createSliderWithTooltip(Slider.Range);
    const filter = useSelector((state: ApplicationState) => state.selector.filter);
    const powerText = useSelector((state: ApplicationState) => state.selector.powerText);
    const filterValues = useSelector((state: ApplicationState) => state.selector.filterValues);
    const products = useSelector((state: ApplicationState) => state.selector.products);
    const loading = useSelector((state: ApplicationState) => state.selector.loading);
    const language = useSelector((state: ApplicationState) => state.translation.language);
    const { onFilterRequest } = useHook(x => x.filterRequest)();
    const primaryFiltersSelected = filter.code && filter.voltageWithPole && filter.applicationShortName;

    useEffect(() => {
        onFilterRequest(filter);
    }, []);

    useEffect(() => {
        const newApplication = getValueFromDictionaryByLanguage('applicationShortNames', filter.applicationShortName ?? '', filterValues, language);
        const newFilter = setApplicationFilter(filter, newApplication);

        dispatch(actionCreators.setFilter({ applicationShortName: newApplication }));

        onFilterRequest(newFilter);
    }, [language]);

    const isPowerValid = (power: string) => !isNaN(Number(power)) && power.length < 6;

    const handleCodeChange = (code: string) => {
        const newFilter = setCodeFilter(filter, code);

        dispatch(actionCreators.setFilter({ code }));

        onFilterRequest(newFilter);
    };

    const handleVoltagePolesChange = (voltageWithPole: string) => {
        const newFilter = setVoltageFilter(filter, voltageWithPole);

        dispatch(actionCreators.setFilter({ voltageWithPole }));

        onFilterRequest(newFilter);
    };

    const handleApplicationChange = (applicationShortName: string) => {
        const newFilter = setApplicationFilter(filter, applicationShortName);

        dispatch(actionCreators.setFilter({ applicationShortName }));

        onFilterRequest(newFilter);
    };

    const handlePowerRangeChange = (power: number[]) => {
        let newFilter: Filter = filter;

        if (power[0] > filterValues.currentMinValue) {
            newFilter = setCurrentMinFilter(filter, filterValues.currentMinValue);
            dispatch(actionCreators.setFilter(newFilter));
            dispatch(actionCreators.addSnackbar({ text: translate(TRANSLATIONS.error.wrongMinMax), type: SnackbarType.Error }));
            dispatch(actionCreators.setPowerText([filterValues.currentMinValue.toString(), powerText[1]]));
        } else if (power[1] < filterValues.currentMaxValue) {
            newFilter = setCurrentMaxFilter(newFilter, filterValues.currentMaxValue);
            dispatch(actionCreators.setFilter(newFilter));
            dispatch(actionCreators.addSnackbar({ text: translate(TRANSLATIONS.error.wrongMaxMin), type: SnackbarType.Error }));
            dispatch(actionCreators.setPowerText([powerText[0], filterValues.currentMaxValue.toString()]));
        } else {
            newFilter = setCurrentMinMaxFilter(filter, power[0], power[1]);
            dispatch(actionCreators.setFilter(newFilter));
            dispatch(actionCreators.setPowerText(power.map(String)));
        }

        onFilterRequest(newFilter);
    };

    const handleMinPowerInputOnBlur = (event: FocusEvent<HTMLInputElement>) => {
        const power = event.target.value;
        let newFilter: Filter = {};

        if (isPowerValid(power)) {
            if (Number(power) > filterValues.currentMinValue) {
                newFilter = setCurrentMinFilter(filter, filterValues.currentMinValue);
                dispatch(actionCreators.setFilter(newFilter));
                dispatch(actionCreators.addSnackbar({ text: translate(TRANSLATIONS.error.wrongMinMax), type: SnackbarType.Error }));
                dispatch(actionCreators.setPowerText([filterValues.currentMinValue.toString(), powerText[1]]));
            } else {
                newFilter = setCurrentMinFilter(filter, Number(power));
                dispatch(actionCreators.setFilter(newFilter));
                dispatch(actionCreators.setPowerText([power, powerText[1]]));
            }

            onFilterRequest(newFilter);
        }
    };

    const handleMaxPowerInputOnBlur = async (event: FocusEvent<HTMLInputElement>) => {
        const power = event.target.value;
        let newFilter: Filter = {};

        if (isPowerValid(power)) {
            if (Number(power) < filterValues.currentMaxValue) {
                newFilter = setCurrentMaxFilter(filter, filterValues.currentMaxValue);
                dispatch(actionCreators.setFilter(newFilter));
                dispatch(actionCreators.addSnackbar({ text: translate(TRANSLATIONS.error.wrongMaxMin), type: SnackbarType.Error }));
                dispatch(actionCreators.setPowerText([powerText[0], filterValues.currentMaxValue.toString()]));
            } else {
                newFilter = setCurrentMaxFilter(filter, Number(power));
                dispatch(actionCreators.setFilter(newFilter));
                dispatch(actionCreators.setPowerText([powerText[0], power]));
            }

            onFilterRequest(newFilter);
        }
    };

    return (
        <div className='selector'>
            <KeyVisual height={360} url={Banner}>
                <Flex direction={FlexDirection.Column} justification={FlexJustification.Center}>
                    <ContentContainer>
                        <Jumbotron>
                            <div className='primary-filters'>
                                <Loader loading={loading}>
                                    <div className='title'>{translate(TRANSLATIONS.selector.title)}</div>
                                    <div className='dropdown-filters'>
                                        <Select
                                            label={translate(TRANSLATIONS.selector.code)}
                                            value={filter?.code}
                                            values={getValuesByLanguageKey(filterValues.codes, language, Language[Language.En].toLocaleUpperCase())}
                                            mapToString={x => x?.toString() ?? ''}
                                            onSelect={x => handleCodeChange(x ?? '')}
                                        />
                                        <Select
                                            label={translate(TRANSLATIONS.selector.voltage)}
                                            value={filter?.voltageWithPole}
                                            values={filterValues.polesWithVoltageTypes}
                                            mapToString={x => x?.toString() ?? ''}
                                            onSelect={x => handleVoltagePolesChange(x ?? '')}
                                        />
                                        <Select
                                            label={translate(TRANSLATIONS.selector.application)}
                                            value={filter?.applicationShortName}
                                            values={getValuesByLanguageKey(filterValues.applicationShortNames, language)}
                                            mapToString={x => x?.toString() ?? ''}
                                            onSelect={x => handleApplicationChange(x ?? '')}
                                        />
                                    </div>
                                    <div className='range-title'>{translate(TRANSLATIONS.selector.rangeTitle)}</div>
                                    <div className='power-slider'>
                                        <Range
                                            min={filterValues.currentMinMaxValue}
                                            max={filterValues.currentMaxMinValue}
                                            defaultValue={[filter.currentMinValue ?? 0, filter.currentMaxValue ?? 0]}
                                            pushable
                                            step={1}
                                            onAfterChange={handlePowerRangeChange}
                                            disabled={!primaryFiltersSelected}
                                        />
                                        <div className='power-input-container'>
                                            <TextInput
                                                name='min-power-input'
                                                value={primaryFiltersSelected ? powerText[0] : '-'}
                                                onChange={power => dispatch(actionCreators.setPowerText([power, powerText[1]]))}
                                                onBlur={handleMinPowerInputOnBlur}
                                                label=''
                                                disabled={!primaryFiltersSelected}
                                            />
                                            <div className={`power-line ${!primaryFiltersSelected ? 'disabled' : ''}`}>__</div>
                                            <TextInput
                                                name='max-power-input'
                                                value={primaryFiltersSelected ? powerText[1] : '-'}
                                                onChange={power => dispatch(actionCreators.setPowerText([powerText[0], power]))}
                                                onBlur={handleMaxPowerInputOnBlur}
                                                label=''
                                                disabled={!primaryFiltersSelected}
                                            />
                                            <div className={`power-unit ${!primaryFiltersSelected ? 'disabled' : ''}`}><p>A</p></div>
                                        </div>
                                    </div>
                                </Loader>
                            </div>
                        </Jumbotron>
                    </ContentContainer>
                </Flex>
            </KeyVisual>
            <Section color={SectionColor.LightSand} style={{ paddingTop: 15 }}>
                <ContentContainer>
                    <Loader loading={loading}>
                        <SelectorTagBar backgroundColor={SectionColor.LightSand} />
                        <div className='results'>
                            {primaryFiltersSelected ?
                                products?.length ?
                                    <Carousel slidesToShow={4} responsive={carouselResponsive}>
                                        {products.map((x, i) =>
                                            <div key={x.id}>
                                                <Flex key={i} direction={FlexDirection.Row} justification={FlexJustification.Center}>
                                                    <Card key={i} product={x} />
                                                </Flex>
                                            </div>
                                        )}
                                    </Carousel> :
                                    !loading && <div className='results-placeholder'>
                                        {translate(TRANSLATIONS.error.noResults)}
                                    </div> :
                                !loading && <div className='results-placeholder'>
                                    {translate(TRANSLATIONS.error.missingFilter)}
                                </div>
                            }
                        </div>
                    </Loader>
                </ContentContainer>
            </Section>
        </div>
    );
};

export default Selector;
