import { useDispatch, useSelector } from 'react-redux';
import { SnackbarType } from '../../components/common/Snackbar';
import { INITIAL_POWERS, TRANSLATIONS } from '../../constants';
import { Filter, FilterValues } from '../../models';
import { Api, apiCall, getValueFromDictionaryByLanguage, setCurrentMaxFilter, setCurrentMinFilter, setCurrentMinMaxFilter } from '../../services';
import { actionCreators, ApplicationState } from '../../store';
import { useTranslate } from '../common';

export const useFilterRequest = () => {
    const dispatch = useDispatch();
    const translate = useTranslate();
    const filter = useSelector((state: ApplicationState) => state.selector.filter);
    const filterValues = useSelector((state: ApplicationState) => state.selector.filterValues);
    const language = useSelector((state: ApplicationState) => state.translation.language);
    const primaryFiltersSelected = filter.code && filter.voltageWithPole && filter.applicationShortName;

    const handleFilterRequest = async (filter: Filter) => {
        dispatch(actionCreators.toggleLoading());

        await apiCall(
            Api.getFilterValues({
                codeEn: filter.code ?? '',
                polesWithVoltageType: filter.voltageWithPole ?? '',
                applicationShortEn: getValueFromDictionaryByLanguage('applicationShortNames', filter?.applicationShortName ?? '', filterValues, language, true),
                currentMin: primaryFiltersSelected ? filter.currentMinValue : null,
                currentMax: primaryFiltersSelected ? filter.currentMaxValue : null
            }),
            async x => {
                const newFilters = await handleRangeSizeChange(x.data.filterValues, filter.currentMinValue == null && filter.currentMaxValue == null);

                dispatch(actionCreators.setFilterValues(x.data.filterValues));
                dispatch(actionCreators.setProducts(x.data.products));

                if (newFilters) {
                    dispatch(actionCreators.setFilter({ currentMinValue: newFilters.currentMinValue, currentMaxValue: newFilters.currentMaxValue }));
                    dispatch(actionCreators.setPowerText([`${newFilters.currentMinValue}`, `${newFilters.currentMaxValue}`]));
                }
            },
            async () => {
                dispatch(actionCreators.addSnackbar({ text: translate(TRANSLATIONS.error.noResults), type: SnackbarType.Error }));
            }
        );

        dispatch(actionCreators.toggleLoading());
    };

    const handleRangeSizeChange = async (filterValues: FilterValues, primaryFilterChange: boolean) => {
        let newFilter: Filter = filter;
        const isMaxEmptyOrTooSmall =
            filter?.currentMaxValue == null || filter?.currentMaxValue == undefined || filterValues.currentMaxValue > (filter?.currentMaxValue ?? INITIAL_POWERS.max);
        const isMinEmptyOrTooBig =
            filter?.currentMinValue == null || filter?.currentMinValue == undefined || filterValues.currentMinValue < (filter?.currentMinValue ?? INITIAL_POWERS.min);

        if (primaryFilterChange) {
            newFilter = setCurrentMinMaxFilter(newFilter, filterValues.currentMinValue, filterValues.currentMaxValue);
        } else {
            if (isMinEmptyOrTooBig && isMaxEmptyOrTooSmall) {
                newFilter = setCurrentMinMaxFilter(newFilter, filterValues.currentMinValue, filterValues.currentMaxValue);
            } else if (isMinEmptyOrTooBig) {
                newFilter = setCurrentMinFilter(newFilter, filterValues.currentMinValue);
            } else if (isMaxEmptyOrTooSmall) {
                newFilter = setCurrentMaxFilter(newFilter, filterValues.currentMaxValue);
            } else {
                return;
            }
        }

        return newFilter;
    };

    return {
        onFilterRequest: handleFilterRequest
    };
};
