import { combineReducers, createStore } from 'redux';
import { ApplicationState, reducers } from './';
import { composeWithDevTools } from '@redux-devtools/extension';

const configureStore = (initialState?: ApplicationState) => {
    const rootReducer = combineReducers({
        ...reducers
    });

    return createStore(
        rootReducer,
        initialState,
        composeWithDevTools(
            // Put other enhancers here
        )
    );
};

export default configureStore;
