import { Language } from '../enums';
import { FilterValues } from '../models';

export const getValueFromDictionaryByLanguage = (key: keyof typeof filterValues, dictionaryValue: string, filterValues: FilterValues, language: Language, isEnglishNeeded = false) => {
    const foundDictionary = Object.entries(filterValues[key]).find(x => x[1][Language[language].toLocaleUpperCase()] === dictionaryValue);

    return foundDictionary ?
        isEnglishNeeded ? foundDictionary[1]['EN'] : foundDictionary[1][Language[language].toLocaleUpperCase()] :
        '';
};

export const getValuesByLanguageKey = (values: Record<string, string>[], language: Language, languageKey?: string): string[] => values.map(x => languageKey ? x[languageKey] : x[Language[language].toLocaleUpperCase()]);
