import { Filter } from '../models';

export const setCodeFilter = (filter: Filter, code: string): Filter => ({ ...filter, code, currentMinValue: null, currentMaxValue: null });

export const setApplicationFilter = (filter: Filter, applicationShortName: string): Filter => ({ ...filter, applicationShortName, currentMinValue: null, currentMaxValue: null });

export const setVoltageFilter = (filter: Filter, voltageWithPole: string): Filter => ({ ...filter, voltageWithPole, currentMinValue: null, currentMaxValue: null });

export const setCurrentMinFilter = (filter: Filter, currentMinValue: number): Filter => ({ ...filter, currentMinValue });

export const setCurrentMaxFilter = (filter: Filter, currentMaxValue: number): Filter => ({ ...filter, currentMaxValue });

export const setCurrentMinMaxFilter = (filter: Filter, currentMinValue: number, currentMaxValue: number): Filter => ({ ...filter, currentMinValue, currentMaxValue });

export const resetCodeAndRange = (filter: Filter): Filter => ({ ...filter, code: '', currentMinValue: null, currentMaxValue: null });

export const resetApplicationAndRange = (filter: Filter): Filter => ({ ...filter, applicationShortName: '', currentMinValue: null, currentMaxValue: null });

export const resetVoltageAndRange = (filter: Filter): Filter => ({ ...filter, voltageWithPole: '', currentMinValue: null, currentMaxValue: null });
