import { Reducer } from 'redux';
import { Filter, FilterValues, Product } from '../models';

export interface State {
    filter: Filter;
    powerText: string[];
    filterValues: FilterValues;
    products?: Product[];
    loading: boolean;
}

interface SetFilter {
    type: 'SET_FILTER';
    filter: Filter;
}

interface SetPowerTextAction {
    type: 'SET_POWER_TEXT';
    powerText: string[];
}

interface SetFilterValuesAction {
    type: 'SET_FILTER_VALUES';
    filterValues: FilterValues;
}

interface SetProductsAction {
    type: 'SET_PRODUCTS';
    products: Product[];
}

interface ToggleLoadingAction {
    type: 'TOGGLE_LOADING';
}

type KnownAction = SetFilterValuesAction | SetFilter | SetPowerTextAction |SetFilterValuesAction | SetProductsAction | ToggleLoadingAction;

export const actionCreators = {
    setFilter: (filter: Filter): SetFilter => ({ type: 'SET_FILTER', filter }),
    setPowerText: (powerText: string[]): SetPowerTextAction => ({ type: 'SET_POWER_TEXT', powerText }),
    setFilterValues: (filterValues: FilterValues): SetFilterValuesAction => ({ type: 'SET_FILTER_VALUES', filterValues }),
    setProducts: (products: Product[]): SetProductsAction => ({ type: 'SET_PRODUCTS', products }),
    toggleLoading: (): ToggleLoadingAction => ({ type: 'TOGGLE_LOADING' })
};

export const reducer: Reducer<State, KnownAction> = (state, action) => {
    if (state === undefined) {
        return {
            filterValues: {
                codes: [],
                polesWithVoltageTypes: [],
                applicationShortNames: [],
                currentMinValue: 0,
                currentMaxValue: 0,
                currentMinMaxValue: 0,
                currentMaxMinValue: 0
            },
            filter: {
                code: '',
                voltagesWithPole: '',
                applicationShortName: '',
                currentMinValue: null,
                currentMaxValue: null
            },
            powerText: [],
            loading: false
        };
    }

    switch (action.type) {
        case 'SET_FILTER':
            return { ...state, filter: { ...state.filter, ...action.filter } };
        case 'SET_FILTER_VALUES':
            return { ...state, filterValues: { ...state.filterValues, ...action.filterValues } };
        case 'SET_POWER_TEXT':
            return { ...state, powerText: action.powerText };
        case 'SET_PRODUCTS':
            return { ...state, products: action.products };
        case 'TOGGLE_LOADING':
            return { ...state, loading: !state.loading };
        default:
            return state;
    }
};
